<template>
	<div
		class="colculate-regist pa-3"
	>
		<v-simple-table
			class="mt-5"
		>
			<caption
				class="pb-1 mb-1 text-left font-weight-bold"
				style="border-bottom: 2px solid #00BFA5;"
			>
				결제정보
			</caption>
			<tbody
			>
				<tr>
					<th>상품명</th>
					<td>
						<v-text-field
							v-model="item.goodsName"
							placeholder="상품명을 입력하세요"
							hide-details
							color="success2"
							:rules="[rules.goodsName]"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>판매금액</th>
					<td>
						<v-text-field
							v-model="item.totAmt"
							placeholder="판매금액을 입력하세요"
							hide-details
							color="success2"
							:rules="[rules.totAmt]"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>연락처</th>
					<td>
						<v-text-field
							v-model="item.buyerTel"
							placeholder="구매자 연락처를 입력하세요"
							hide-details
							color="success2"
							type="number"
							:rules="[rules.buyerTel]"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>부가세</th>
					<td>
						<v-radio-group
							v-model="item.taxFlag"
							row
							class="pa-0 ma-0 mt-3"
						>
							<v-radio
								v-for="(item, index) in codes.taxFlag"
								:key="index"
								:label="item.codeName"
								:value="item.code"
								color="success2"
								hide-details
							>
							</v-radio>
						</v-radio-group>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		
		<v-simple-table
			class="mt-5"
		>
			<caption
				class="pb-1 mb-1 text-left font-weight-bold"
				style="border-bottom: 2px solid #00BFA5;"
			>
				결제카드 정보
			</caption>
			<thead>
				<tr>
					<td colspan="2">
						<v-radio-group
							v-model="item.cardCate"
							row
							class="pa-0 ma-0 mt-2"
						>
							<v-row>
								<v-col
									cols="2"
									class="pa-0 ma-0"
								></v-col>
								<v-col
									class="pa-0 ma-0"
								>
									<v-radio
										label="개인카드"
										value="1"
										color="success2"
										hide-details
									>
									</v-radio>
								</v-col>
								<v-col
									class="pa-0 ma-0"
								>
									<v-radio
										label="법인카드"
										value="2"
										color="success2"
										hide-details
									>
									</v-radio>
								</v-col>
								<v-col
									cols="1"
									class="pa-0 ma-0"
								></v-col>
							</v-row>
						</v-radio-group>
					</td>
				</tr>
			</thead>
			<tbody
				class="tt"
			>
				<tr>
					<td colspan="2">
						<v-text-field
							v-model="item.cardNo"
							label="카드번호"
							placeholder="1111-2222-3333-4444"
							hide-details
							color="success2"
							type="number"
							:rules="[rules.cardNo]"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<v-row>
							<v-col>
								<v-row
									class="pa-0 ma-0"
								>
									<v-col
										class="pa-0 ma-0"
									>
										<v-text-field
											v-model="item.expireMm"
											label="유효기간"
											placeholder="MM"
											hide-details
											color="success2"
											type="number"
											:rules="[rules.expireMm]"
										></v-text-field>
									</v-col>
									<v-col
										class="pa-0 ma-0"
									>
										<v-text-field
											v-model="item.expireYy"
											placeholder="YY"
											hide-details
											color="success2"
											type="number"
											:rules="[rules.expireYy]"
										></v-text-field>
									</v-col>
								</v-row>
							</v-col>
							<v-col>
								<v-text-field
									v-model="item.cardPassword"
									label="비밀번호"
									placeholder="앞 2자리"
									hide-details
									color="success2"
									type="password"
									:rules="[rules.cardPassword]"
								></v-text-field>
							</v-col>
						</v-row>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<v-row>
							<v-col
								class="text-left"
							>
								<v-select
									v-model="item.installment"
									:item-text="item.text"
									:item-value="item.value"
									:items="installment"
									label="할부"
									class="d-inline-block mr-5"
									width="100%"
									hide-details
									color="success2"
									:disabled="installment_disabled"
								></v-select>
							</v-col>
							<v-col
								class="text-left"
							>
								<v-text-field
									v-model="item.buyerBirth"
									label="본인확인"
									placeholder="주민번호 앞 6자리"
									hide-details
									color="success2"
									type="number"
									:rules="[rules.buyerBirth]"
								></v-text-field>
							</v-col>
						</v-row>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<v-row>
							<v-col>
								<v-select
									v-model="item.interestFree"
									:item-text="item.text"
									:item-value="item.value"
									:items="interestFree"
									label="무이자"
									class="d-none mr-5"
									hide-details
									color="success2"
									:disabled="installment_disabled"
								></v-select>
							</v-col>
							<v-col
								class="text-left"
								style="font-size: 0.7em;"
							>
								*개인: 주민번호 앞 6자리 <br/>
								*법인: 사업자번호 10자리
							</v-col>
						</v-row>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		<div class="mt-5 mb-10">
			<v-btn
				block
				color="success2 darken-1"
				@click="save"
				:disabled="is_save ? false : true"
			>
				결제하기
			</v-btn>
		</div>
		
	</div>
</template>

<script>
	export default{
		name: 'PaymentRegist'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
//			this.item = this.sample
		}
		,props: ['callBack']
		,data: function(){
			return {
				program: {
					title: '신용카드 수기결제'
					,not_navigation: true
				}
				,is_save: false
				,item: {
					buyerBirth: ''
					,buyerTel: ''
					,cardCate: '1'
					,cardNo: ''
					,cardPassword: ''
					,expireMm: ''
					,expireYy: ''
					,goodsName: ''
					,installment: '00'
					,interestFree: ''
					,payType: 'card'
					,taxFlag: '00'
					,totAmt: ''
				}
				,applyResult: {
				
				}
				,sample: {
					buyerBirth: '6738601311'
					,buyerTel: '01024892570'
					,cardCate: '1'
					,cardNo: '5205041135101901'
					,cardPassword: '04'
					,expireMm: '02'
					,expireYy: '25'
					,goodsName: 'test'
					,installment: '00'
					,interestFree: '0'
					,payType: 'card'
					,taxFlag: '00'
					,totAmt: '1004'
				}
				,interestFree: [
					{
						text: '선택하세요'
						,value: ''
					}
					,{
						text: '무이자'
						,value: '0'
					}
					,{
						text: '할부 이자'
						,value: '1'
					}
				]
				,installment: [
					{
						text: '일시불'
						,value: '00'
					}
					,{
						text: '2개월'
						,value: '02'
					}
					,{
						text: '3개월'
						,value: '03'
					}
					,{
						text: '4개월'
						,value: '04'
					}
					,{
						text: '5개월'
						,value: '05'
					}
					,{
						text: '6개월'
						,value: '06'
					}
					,{
						text: '7개월'
						,value: '07'
					}
					,{
						text: '8개월'
						,value: '08'
					}
					,{
						text: '9개월'
						,value: '09'
					}
					,{
						text: '10개월'
						,value: '10'
					}
					,{
						text: '11개월'
						,value: '11'
					}
					,{
						text: '12개월'
						,value: '12'
					}
				]
				,codes: {
					taxFlag: [
						{
							code: '00'
							,codeName: '과세'
						}
						,{
							code: '01'
							,codeName: '비과세'
						}
					]
				}
				,rules:{
					goodsName: value => {
						let max = 50
						if(value.length > max){
							this.item.goodsName = this.item.goodsName.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,buyerTel: value => {
						let max = 12
						if(value.length > max){
							this.item.buyerTel = this.item.buyerTel.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,totAmt: value => {
						let max = 8
						if(value.length > max){
							this.item.totAmt = this.item.totAmt.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,cardNo: value => {
						let max = 16
						if(value.length > max){
							this.item.cardNo = this.item.cardNo.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,cardPassword: value => {
						let max = 2
						if(value.length > max){
							this.item.cardPassword = this.item.cardPassword.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,expireMm: value => {
						let max = 2
						if(value.length > max){
							this.item.expireMm = this.item.expireMm.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,expireYy: value => {
						let max = 2
						if(value.length > max){
							this.item.expireYy = this.item.expireYy.substring(0, max)
							return false
						}else{
							return true
						}
					}
					,buyerBirth: value => {
						
						let max = 6
						
						if(this.item.cardCate != "1"){
							max = 10
						}
						
						if(value.length > max){
							this.item.buyerBirth = this.item.buyerBirth.substring(0, max)
							return false
						}else{
							return true
						}
					}
				}
				
			}
		}
		,computed: {
			installment_disabled: function(){
				if(this.item.totAmt >= 50000){
					return false
				}else{
					this.$set(this.item, 'installment', '00')
					this.$set(this.item, 'interestFree', '')
					return true
				}
			}
		}
		,methods: {
			save: function(){
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/pay/approval'
					,request_params: this.item
					,callBack: 'result'
					,authorize: true
					,error_message: true
				})
			}
			,cancel: function(){
				this.$emit('confirm', {
					title: '취소하시겠습니까?'
					,to: '/Home'
				})
			}
			,result: function(call){
				if(call.result){
					if(call.items.content.payResponseCode == '0001' || call.items.content.payResponseCode == '0000' || call.items.content.payResponseCode == '00'){
						this.$emit('aletMsg', 'success', '결제가 완료되었습니다.')
						this.$router.push('/Payment/Item/' + call.items.content.payResponseTranSeq)
					}else{
						this.$emit('alertMsg', 'error', call.items.content.payResponseMsg)
					}
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log('watch payment : ' + call.name)
					console.log(call)
					if(call.name == 'result'){
						this.result(call)
					}
				}
			}
			,item: {
				deep: true
				,handler: function(){
					
					for(var col in this.item){

						if(!this.item[col] || this.item[col] == '' || this.item[col] == undefined){
							if(col == 'interestFree'){
								this.is_save = true
							}else{
								this.is_save = false
								return false
							}
						}else{
							this.is_save = true
						}
					}
					
				}
			}
		}
	}
</script>
<style>
	.colculate-regist {}
	.colculate-regist th { padding: 0 !important; text-align: left; border: none !important;}
	.colculate-regist td { padding: 5px 2px !important; height: auto; font-size: 0.8em !important; border: none !important;}
	.colculate-regist .v-input__control {min-height: 0 !important;}
	.colculate-regist .v-input__control input {font-size: 0.8em;}
	.colculate-regist .v-input__slot {margin: 0 !important;}
	
	.colculate-regist .tt label {font-size: 1em !important; color: black;}
	
	
	
	.v-select__selection {
		min-height: 0;
		width: 100%;
		max-width: 100%;
		font-size: 0.9em;
	}
	.v-select__selections input{
		min-height: 0 !important;
		height: 0 !important;
		padding: 0;
	}
	
	.v-select__slot label {
		bottom: 5px !important;
	}
</style>